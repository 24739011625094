import fs from 'fs';
import I18n from 'i18n-js';
import path from 'path';
import yaml from 'yaml';

export const loadLocales = () => process.env.NODE_ENV === 'test'
  ? subProcedures.test()
  : subProcedures.default();

const subProcedures = {
  default() {
    const translations = {};
    const requireContext = require.context('../../../../config/locales', false, /\.yml$/);
    requireContext.keys().forEach((key) => {
      I18n.extend(translations, requireContext(key));
    });
    return translations;
  },
  test() {
    const translations = {};
    const localesPath = path.resolve(
      process.cwd(),
      'config',
      'locales',
    );
    const files = fs.readdirSync(localesPath)
      .filter((file) => /\.ya?ml$/.test(file));
    files.forEach((file) => {
      const raw = fs.readFileSync(
        path.resolve(localesPath, file),
        'utf8',
      );
      I18n.extend(translations, yaml.parse(raw));
    });
    return translations;
  },
};
